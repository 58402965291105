export default {
    get(axios, locationId) {
        return axios.get(`api/inventory/containers?location=${locationId}`);
    },
    create(axios, payload){
        return axios.post(`api/inventory/containers`, payload)
    },
    delete(axios, id){
        return axios.delete(`api/inventory/containers/${id}`)
    },
    update(axios, id, locationId, payload){
        return axios.put(`api/inventory/containers/${id}?location=${locationId}`, payload)
    },
}
