import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import userSettings from '@/userSettings'
import EventBus from '@/EventBus'

import apiAuth from '@/backend/services/UserService';
import apiLocations from '@/backend/services/LocationsService'
import apiContainers from '@/backend/services/ContainerService'
import apiContainerCodes from '@/backend/services/ContainerCodeService'

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        axios: undefined,
        ajaxRefCount: 0,
        ajaxErrorMessages: [],
        disableLoader: false,
        userSettings: undefined,
        appSettings: {},
        locations: [],
        container_codes: [],
        containers: [],
        homeSettings: {
            view_mode: 'alpha',
            selected_alpha: 'a',
        }
    },
    getters: {
        loading: state => {
            if (state.disableLoader) {
                return false;
            }

            return state.ajaxRefCount > 0;
        },
        ajaxErrors: state => {
            return state.ajaxErrorMessages;
        },
        axios: (state) => {
            return state.axios;
        },
        userSettings: (state) => {
            return state.userSettings
        },

        appSettings: (state) => {
            return state.appSettings
        },

        homeViewSettings: (state) => {
            return state.userSettings.home_page
        },

        locations: (state) => {
            return state.locations
        },

        containers: (state) => {
            return state.containers
        },

        container_codes: (state) => {
            return state.container_codes
        },

        selectedLocation: (state) => {
            for (let i = 0; i < state.locations.length; i++) {
                let l = state.locations[i]
                if (l.id == state.userSettings.home_page.selected_location) {
                    return l
                }
            }
            return {
                id: '',
                desc: '',
                containers: []
            }
        },
    },
    actions: {
        clearAjaxErrors: (context) => {
            context.commit('clearAjaxErrors');
        },
        toggleLoader: (context, disabled) => {
            context.state.disableLoader = disabled;
        },
        loadUserSettings: (context) => {
            context.commit('userSettings', userSettings.settings());
        },
        setAppSettings: (context, settings) => {
            context.commit('appSettings', settings);
        },
        login: (context, payload) => {
            apiAuth.login(context.state.axios, payload.username, payload.password)
                .then((response) => {
                    if (!response.data.token) {
                        return Promise.reject("Login failed");
                    }

                    userSettings.refreshToken(response.data.token);
                    context.dispatch('loadUserSettings');
                    context.dispatch('loadContainerCodes')
                    context.dispatch('loadContainers')
                    context.dispatch('loadLocations')
                    if(payload.route.name != "Home"){
                        payload.router.replace('/');
                    }
                });
        },
        logout: () => {
            userSettings.refreshToken('')
        },

        loadContainers: (context) => {
            apiContainers.get(context.state.axios, context.state.userSettings.home_page.selected_location)
                .then((res) => {
                    context.commit('containers', res.data.containers)
                })
        },

        loadContainerCodes: (context) => {
            apiContainerCodes.get(context.state.axios)
                .then((res) => {
                    context.commit('container_codes', res.data.container_codes)
                })
        },

        loadLocations: (context) => {
            apiLocations.get(context.state.axios)
                .then((res) => {
                    context.commit('locations', res.data.locations)
                })
        },

        setSelectedLocation: (context, location) => {
            context.commit('selectedLocation', location.id)
        },

        setHomeViewMode: (context, mode) => {
            context.commit('homeViewMode', mode)
        },

        setHomeViewLetter: (context, letter) => {
            context.commit('homeViewLetter', letter)
        },
    },
    mutations: {
        clearAjaxErrors: (state) => {
            state.ajaxErrorMessages = [];
        },
        userSettings: (state, settings) => {
            state.userSettings = settings;
        },
        appSettings: (state, settings) => {
            state.appSettings = settings

            state.axios = axios.create({
                baseURL: settings.API_URL ? settings.API_URL : 'http://api.sunergeo.local',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            state.axios.interceptors.request.use(
                (config) => {
                    if (config.baseURL == state.appSettings.API_URL) {
                        const token = state.userSettings.token
                        if (token) {
                            config.headers.Authorization = `Bearer ${token}`
                        }
                    }

                    state.ajaxRefCount++;
                    return config;
                },
                (error) => {
                    state.ajaxRefCount--;
                    return Promise.reject(error);
                });

            state.axios.interceptors.response.use(
                (response) => {
                    state.ajaxRefCount--;
                    if (Object.prototype.hasOwnProperty.call(response, 'data') &&
                        Object.prototype.hasOwnProperty.call(response.data, 'token')) {
                        userSettings.refreshToken(response.data.token);
                    }

                    if (state.currentControl) {
                        state.currentControl.focus();
                        state.currentControl = null;
                    }

                    return response;
                },
                (error) => {
                    state.ajaxRefCount--;

                    let message = {
                        response: error.response,
                        html: "Network Error"
                    };

                    //Setup Error Message
                    if (typeof error.response !== 'undefined') {
                        //Setup Generic Response Messages
                        if (error.response.status === 401) {
                            message.html = 'Unauthorized'
                            userSettings.refreshToken('');
                            state.token = "";
                        } else if (error.response.status === 400) {
                            message.html = error.response.statusText
                        } else if (error.response.status === 404) {
                            message.html = 'API Route is Missing or Undefined'
                        } else if (error.response.status === 405) {
                            message.html = 'API Route Method Not Allowed'
                        } else if (error.response.status === 422) {
                            //Validation Message
                        } else if (error.response.status >= 500) {
                            message.html = error.response.statusText
                        }

                        //Try to Use the Response Message
                        if (Object.prototype.hasOwnProperty.call(error, 'response') &&
                            Object.prototype.hasOwnProperty.call(error.response, 'data')) {

                            if (Object.prototype.hasOwnProperty.call(error.response.data, 'error') &&
                                error.response.data.error.length > 0) {
                                message.html = error.response.data.error
                            }
                        }
                    }

                    //Notify main App so it can handle error display...
                    state.ajaxErrorMessages.push(message);
                    EventBus.$emit("ajaxErrors");

                    return Promise.reject(message);
                });
        },

        containers: (state, containers) => {
            state.containers = containers
        },

        container_codes: (state, codes) => {
            state.container_codes = codes
        },

        locations: (state, locations) => {
            state.locations = locations
        },

        selectedLocation: (state, location) => {
            let settings = userSettings.settings();
            settings.home_page.selected_location = location
            userSettings.store(settings)
            state.userSettings = settings
        },

        homeViewMode: (state, mode) => {
            let settings = userSettings.settings();
            settings.home_page.view_mode = mode
            userSettings.store(settings)
            state.userSettings = settings
        },

        homeViewLetter: (state, letter) => {
            let settings = userSettings.settings();
            settings.home_page.selected_letter = letter
            userSettings.store(settings)
            state.userSettings = settings
        },
    }
});

export default store;
