<template>
    <div>
        <modal selected_item="selected_item" @changed="refreshItemView"/>
        <div v-if="viewMode=='alpha'">
            <selector @alphaSelected="refreshItemView"/>
            <b-table
                :fields="fields"
                :items="items"
                hover
                striped
                tbody-tr-class="item-row"
                thead-class="hidden_header"
                @row-clicked="alphaItemClicked"
            />
        </div>
        <div v-if="viewMode=='container'">
            <div v-for="item in items" :key="item.ID" class="row no-gutters">
                <div class="col">
                    <h5>{{ item.container }}</h5>
                    <b-table
                        :fields="fields"
                        :items="item.items"
                        hover
                        striped
                        tbody-tr-class="item-row"
                        thead-class="hidden_header"
                        @row-clicked="rowClicked"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import selector from '@/components/AlphaSelector'
import modal from '@/components/ModalAddOrEditItem'
import api from '@/backend/services/ItemsService'
import EventBus from '@/EventBus'

export default {
    name: "Home",
    components: {
        selector,
        modal,
    },
    data: () => ({
            items: [],
            fields: [
                {
                    key: 'desc',
                },
                {
                    key: 'label',
                    tdClass: 'location-label'
                }
            ],
            selected_item: null,
        }
    ),
    computed: {
        viewMode() {
            return this.$store.getters.homeViewSettings.view_mode
        }
        ,
        containers() {
            return this.$store.getters.containers;
        }
        ,
    }
    ,
    methods: {
        refreshItemView(ltr) {
            if (!ltr) {
                ltr = this.$store.getters.homeViewSettings.selected_letter
            }

            api.get(this.$store.getters.axios, {
                view_mode: this.$store.getters.homeViewSettings.view_mode,
                location: this.$store.getters.homeViewSettings.selected_location,
                term: ltr
            })
                .then((res) => {
                    if (this.$store.getters.homeViewSettings.view_mode == "alpha") {
                        this.$store.dispatch('setHomeViewLetter', ltr)
                    }

                    this.items = []
                    if (res.data) {
                        this.items = res.data.items
                    }
                })
        }
        ,
        rowClicked(record) {
            let item = null;

            for (let i = 0; i < this.items.length; i++) {
                let grp = this.items[i]
                for (let j = 0; j < grp.items.length; j++) {
                    if (record.id == grp.items[j].id) {
                        item = grp.items[j]
                    }
                }
            }

            //Now fill in the location and location, if applicable, so modal dialog has that info to set control values.
            for (let i = 0; i < this.containers.length; i++) {
                let container = this.containers[i]
                if (item.sub_container) {
                    if (container.sub_containers) {
                        for (let j = 0; j < container.sub_containers.length; j++) {
                            let sc = container.sub_containers[j]
                            if (sc.id == item.sub_container) {
                                item.container = container.id
                            }
                        }
                    }
                }
            }

            EventBus.$emit('editItem', item)
        }
        ,
        alphaItemClicked(item) {
            EventBus.$emit('editItem', item)
        }
        ,
    }
    ,
    created() {
        this.refreshItemView(this.$store.getters.homeViewSettings.selected_letter)

        EventBus.$on('setHomeViewMode', (mode) => {
            this.$store.dispatch('setHomeViewMode', mode)
                .then(() => {
                    this.refreshItemView(this.$store.getters.homeViewSettings.selected_letter)
                    if (this.$route.name != "Home") {
                        this.$router.push('home')
                    }
                })
        })
    }
}

</script>

<style>
.hidden_header {
    display: none;
}

.item-row {
    cursor: pointer;
}

.container-label {
    min-width: 125px;
    white-space: nowrap;
}

</style>