let userKey = "inventory.sunergeo.user";

let user = JSON.parse(localStorage.getItem(userKey));
if (!user) {
    user = {
        settings: {
            token: '',
            home_page: {
                selected_location: '',
                selected_letter: 'A',
                view_mode: 'alpha',
            }
        },
    }
}

export default {
    getAuthToken() {
        return user.settings.token;
    },
    settings() {
        return user.settings;
    },
    refreshToken(token){
        user.settings.token = token
        localStorage.setItem(userKey, JSON.stringify(user));
    },
    store(settings) {
        user.settings = settings;
        localStorage.setItem(userKey, JSON.stringify(user));
    }
}
