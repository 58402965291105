<template>
    <div class="row no-gutters">
        <div class="col">
            <b-button-group class="alpha-selector" size="sm">
                <b-button v-if="range.start > 0" @click="pageLeft">
                    <font-awesome-icon :icon="['fas', 'caret-square-left']"/>
                </b-button>
                <b-button v-for="ltr in alphabet_slice" :key="ltr" @click="refresh(ltr)" :pressed="letter == ltr">
                    {{ ltr }}
                </b-button>
                <b-button v-if="range.start + range.length < alphabet.length" @click="pageRight">
                    <font-awesome-icon :icon="['fas', 'caret-square-right']"/>
                </b-button>
            </b-button-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "AlphaSelector",
    data: () => ({
        range: {
            start: 0,
            length: 12
        },
        alphabet: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "123"],
    }),
    computed: {
        alphabet_slice() {
            let slice = []
            for (let i = 0; i < this.range.length; i++) {
                slice.push(this.alphabet[this.range.start + i])
            }
            return slice
        },
        letter(){
            return this.$store.getters.homeViewSettings.selected_letter
        },
    },
    methods: {
        pageRight() {
            this.range.start += this.range.length
            if(this.range.start + this.range.length > this.alphabet.length){
                this.range.start = this.alphabet.length - this.range.length
            } else {
                this.range.length = 11
            }
        },
        pageLeft() {
            this.range.start -= this.range.length
            if(this.range.start <= 1){
                this.range.start = 0
                this.range.length = 12
            }
        },
        refresh(ltr){
            this.$emit('alphaSelected', ltr)
        },
        scrollSelectedLetter(){
            let ltr = this.letter
            if(!ltr){
                return
            }

            let slice = this.alphabet_slice
            let found = slice.includes(ltr)

            while(!found){
                this.pageRight()
                slice = this.alphabet_slice
                found = slice.includes(ltr)
            }
        },
    },
    mounted(){
        //Scroll the selected letter into view...
        this.scrollSelectedLetter()
    }
}
</script>

<style scoped>

.btn-group.alpha-selector {
    display: flex;
}

.alpha-selector .btn {
    flex: 1;
}

</style>