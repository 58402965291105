export default {
    get(axios) {
        return axios.get('api/inventory/locations');
    },

    create(axios, payload){
        return axios.post(`api/inventory/locations`, payload)
    },

    update(axios, locationId, payload){
        return axios.put(`api/inventory/locations/${locationId}`, payload)
    },

    delete(axios, id){
        return axios.delete(`api/inventory/locations/${id}`)
    },
}
