import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home'
import Locations from '@/views/Locations'
import Containers from '@/views/Containers'
import ContainerCodes from '@/views/ContainerCodes'

Vue.use(Router)

let router = new Router({
    routes: [
        {
            path: '/home',
            alias: '/',
            name: 'Home',
            component: Home,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/locations',
            name: 'Locations',
            component: Locations,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/containers',
            name: 'Containers',
            component: Containers,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/container_codes',
            name: 'ContainerCodes',
            component: ContainerCodes,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '*',
            component: () => import('./views/PageNotFound.vue')
        },
    ]
});

export default router;
