export default {
    get(axios) {
        return axios.get('api/inventory/container_codes');
    },
    create(axios, payload){
        return axios.post('api/inventory/container_codes', payload)
    },
    update(axios, id, payload){
        return axios.put(`api/inventory/container_codes/${id}`, payload)
    },
    delete(axios, code){
        return axios.delete(`api/inventory/container_codes/${code}`)
    },
}
