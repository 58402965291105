<template>
    <div>
        <modal/>
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <h5>Containers</h5>
                </div>
                <div class="col-6">
                    <h5>Sub Containers</h5>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <div class="btn btn-primary btn-block" @click="addContainer">
                        Add New Container
                    </div>
                </div>
            </div>
            <div v-for="container in containers" :key="container.id" class="row mt-3 mb-3">
                <div class="col-6" @click="editContainer(container)">
                    <button class="btn btn-info btn-block">
                        {{container.container_code}}-{{ container.label }}
                    </button>
                </div>
                <div class="col-6">
                    <button v-if="container.sub_containers" class="btn btn-info btn-block" @click="editContainer(container)">
                        {{ sub_containers(container.sub_containers) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/EventBus";
import modal from "@/components/ModalAddOrEditContainer"

export default {
    name: "Containers",
    components: {
        modal,
    },
    data: () => ({
        fields: ['container_code', 'label'],
        selected_container: null,
    }),
    computed: {
        location() {
            return this.$store.getters.homeViewSettings.selected_location
        },
        containers(){
            return this.$store.getters.containers
        }
    },
    methods: {
        sub_containers(sub_containers) {
            let sc = ""
            if (!sub_containers) {
                return sc
            }

            for (let i = 0; i < sub_containers.length; i++) {
                sc += sub_containers[i].label
                if (i < sub_containers.length - 1) {
                    sc += ", "
                }
            }
            return sc
        },
        addContainer() {
            EventBus.$emit('addContainer')
        },
        editContainer(container) {
            EventBus.$emit('editContainer', container)
        },
        editSubContainer(container) {
            console.log("Editing sub-containers for " + container.id)
            EventBus.$emit('editSubContainer', container)
        },
    },
    mounted() {
        if(this.containers.length == 0){
            EventBus.$emit('loadContainers')
        }

        EventBus.$on('editContainer', () => {
            this.$bvModal.show('modal-edit-inventory-container');
        })

        EventBus.$on('addContainer', () => {
            this.$bvModal.show('modal-edit-inventory-container');
        })
    }
}
</script>

<style scoped>

</style>