<template>
    <div>
        <modal/>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <h5>Locations</h5>
                    <b-table
                        :fields="fields"
                        :items="locations"
                        hover
                        select-mode="single"
                        selectable
                        striped
                        thead-class="no-header"
                        @row-selected="onRowSelected"
                    >
                        <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                                <span aria-hidden="true">&check;</span>
                                <span class="sr-only">Selected</span>
                            </template>
                            <template v-else>
                                <span aria-hidden="true">&nbsp;</span>
                                <span class="sr-only">Not selected</span>
                            </template>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="row">
                <div class="col" v-if="!selected">
                    <b-button
                        block
                        variant="primary"
                        @click="addLocation"
                    >
                        New Location
                    </b-button>
                </div>
                <div class="col" v-if="selected">
                    <b-button
                        block
                        variant="dark"
                        @click="selectLocation"
                    >
                        Use This Location
                    </b-button>
                </div>
                <div class="col" v-if="selected">
                    <b-button
                        block
                        variant="dark"
                        @click="editLocation"
                    >
                        Edit
                    </b-button>
                </div>
                <div class="col" v-if="selected && selected.canDelete">
                    <b-button
                        block
                        variant="dark"
                        @click="deleteLocation"
                    >
                        Delete
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modal from "@/components/ModalAddOrEditLocation"
import EventBus from "@/EventBus";
import api from "@/backend/services/LocationsService";

export default {
    name: "Locations",
    components: {
        modal,
    },
    data() {
        return {
            fields: ['selected', 'id', 'desc'],
            selected: []
        }
    },
    computed: {
        locations() {
            return this.$store.getters.locations
        }
    },
    methods: {
        onRowSelected(items) {
            this.selected = items[0]
        },
        selectLocation() {
            this.$store.dispatch('setSelectedLocation', this.selected)
            this.$router.push('home')
        },
        addLocation() {
            EventBus.$emit('addLocation')
        },
        deleteLocation(){
            if (!confirm("Are you sure you want to delete this location?")) {
                return
            }

            api.delete(this.$store.getters.axios, this.selected.id)
                .then(() => {
                    this.$nextTick(() => {
                        this.$store.dispatch('loadLocations')
                    })
                })
        },
        editLocation() {
            EventBus.$emit('editLocation', {
                id: this.selected.id,
                desc: this.selected.desc
            })
        },
    },
    mounted() {
        if (this.$store.getters.locations.length == 0) {
            this.$store.dispatch('loadLocations')
        }

        EventBus.$on('addLocation', () => {
            this.$bvModal.show('modal-edit-inventory-location');
        })

        this.selected = null
    }
}
</script>

<style>
.no-header {
    display: none !important;
}
</style>