<template>
    <b-modal
        id="modal-edit-inventory-location"
        ref="modal"
        :okTitle="title"
        :title="title"
        size="md"
        @hidden="reset"
        @ok="handleOk"
    >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-row>
                <b-col class="col-6">Location Code:</b-col>
                <b-col class="col-6">Label:</b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-input v-model="location.id"/>
                </b-col>
                <b-col class="col-6">
                    <b-input v-model="location.desc"/>
                </b-col>
            </b-form-row>
        </form>
    </b-modal>
</template>

<script>
import EventBus from '@/EventBus'
import api from "@/backend/services/LocationsService";

export default {
    name: "ModalAddOrEditLocation",
    data: () => ({
        location: { },
        original_id: '',
    }),
    computed: {
        title() {
            return this.original_id ? 'Update' : 'Create Location';
        },
    },
    methods: {
        reset() {
            this.location = {};
            this.original_id = ''
        },
        handleOk(e) {
            e.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            if (this.original_id) {
                api.update(this.$store.getters.axios, this.original_id, this.location)
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$store.dispatch('loadLocations')
                        })
                    })
            } else {
                api.create(this.$store.getters.axios, {
                    id: this.location.id,
                    desc: this.location.desc
                })
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$store.dispatch('loadLocations')
                        })
                    })
            }
        },
    },
    created() {
        EventBus.$on('editLocation', (payload) => {
            if (payload) {
                this.location = payload
                this.original_id = payload.id
            }
            this.$bvModal.show('modal-edit-inventory-location');
        })
    },
}
</script>

<style scoped>

</style>
