<template>
    <div>
        <modal/>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <h5>Container Codes</h5>
                </div>
            </div>
            <div v-for="code in container_codes" :key="code.code" class="row mt-3 mb-3">
                <div class="col" @click="editContainerCode(code)">
                    <button class="btn btn-info btn-block">
                        {{ code.code }}-{{ code.description }}
                    </button>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <div class="btn btn-primary btn-block" @click="addContainerCode">
                        Add New Container Code
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/EventBus";
import modal from "@/components/ModalAddOrEditContainerCode"

export default {
    name: "ContainerCodes",
    components: {
        modal,
    },
    data: () => ({
    }),
    computed: {
        container_codes() {
            return this.$store.getters.container_codes
        },
    },
    methods: {
        addContainerCode() {
            EventBus.$emit('editContainerCode')
        },
        editContainerCode(code) {
            EventBus.$emit('editContainerCode', code)
        },
    },
    created(){
        if(this.container_codes.length == 0){
            EventBus.$emit('loadContainerCodes')
        }
    }
}
</script>

<style scoped>

</style>