import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'

import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {faKey} from '@fortawesome/free-solid-svg-icons'
import {faBoxOpen} from '@fortawesome/free-solid-svg-icons'
import {faFont} from '@fortawesome/free-solid-svg-icons'
import {faCaretSquareRight, faCaretSquareLeft, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons'
import Icon from 'vue-awesome/components/Icon'

import toast from 'vue-toasted';
import store from '@/store';
import router from '@/router';
import appSettings from '@/appSettings'

Vue.config.productionTip = false

Vue.component('v-icon', Icon)
Vue.use(toast, {
    duration: 5000,
    iconPack: 'fontawesome'
});

Vue.use(Vuesax)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

library.add(faExclamationTriangle)
library.add(faUser)
library.add(faKey)
library.add(faBoxOpen)
library.add(faFont)
library.add(faCaretSquareRight)
library.add(faCaretSquareLeft)
library.add(faPlus)
library.add(faTrash)
dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('v-icon', Icon)

const getAppSettings = async () => {
    let settings = await appSettings.init();
    await store.dispatch('setAppSettings', settings);
}

getAppSettings().then(() => {
    new Vue({
        store,
        router,
        render: h => h(App),
        beforeCreate() {
            this.$store.dispatch('loadUserSettings');
        }
    }).$mount('#app')
})
