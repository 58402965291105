export default {
    get(axios, payload) {
        return axios.get(`api/inventory/items?location=${payload.location}&mode=${payload.view_mode}&q=${payload.term}`);
    },
    create(axios, payload){
        return axios.post(`api/inventory/items`, payload)
    },
    update(axios, id, payload){
        return axios.put(`api/inventory/items/${id}`, payload)
    },
    delete(axios, id){
        return axios.delete(`api/inventory/items/${id}`)
    }
}
