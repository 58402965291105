<template>
    <div>
        <b-navbar id="sidebar-nav" sticky toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand exact exact-active-class="active" to="/home">{{ selectedLocation.desc }}</b-navbar-brand>
            <b-navbar-nav>
                <b-button-group size="sm">
                    <b-button :pressed="viewMode=='alpha'" @click="setViewMode('alpha')">
                        <font-awesome-icon :icon="['fas', 'font']"/>
                    </b-button>
                    <b-button :pressed="viewMode=='container'" @click="setViewMode('container')">
                        <font-awesome-icon :icon="['fas', 'box-open']"/>
                    </b-button>
                </b-button-group>
            </b-navbar-nav>

            <b-navbar-nav>
                <b-button-group size="sm">
                    <b-button @click="addItem">
                        <font-awesome-icon :icon="['fas', 'plus']"/>
                    </b-button>
                </b-button-group>
            </b-navbar-nav>

            <!--
            <b-navbar-toggle target="nav-collapse"/>
            -->
            <b-button v-b-toggle.sidebar-footer>Menu</b-button>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item exact exact-active-class="active" to="/locations">Locations</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav>
                    <b-nav-item exact exact-active-class="active" to="/locations">Containers</b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <!--
                <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                        <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                    </b-nav-form>

                    <b-nav-item-dropdown right>
                        <template #button-content>
                            <em>User</em>
                        </template>
                        <b-dropdown-item href="#">Profile</b-dropdown-item>
                        <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
                -->
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import EventBus from '@/EventBus'

export default {
    name: "Navbar",
    computed: {
        viewMode() {
            return this.$store.getters.homeViewSettings.view_mode
        },
        selectedLocation() {
            return this.$store.getters.selectedLocation
        }
    },
    methods: {
        setViewMode(mode) {
            EventBus.$emit('setHomeViewMode', mode)
        },
        addItem() {
            EventBus.$emit('addItem');
        },
    },
    mounted() {
        if (this.$store.getters.locations.length == 0) {
            this.$store.dispatch('loadLocations')
        }
    }
}
</script>

<style>
</style>