<template>
    <b-modal
        id="modal-edit-inventory-container-code"
        ref="modal"
        :okTitle="title"
        :title="title"
        size="md"
        @hidden="reset"
        @ok="handleOk"
    >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-row>
                <b-col class="col">Container Code:</b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col">
                    <b-input v-model="container_code.code"/>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col">Description:</b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col">
                    <b-input v-model="container_code.description"/>
                </b-col>
            </b-form-row>

            <b-form-row v-if="original_code" class="mt-5">
                <b-col class="col-12 text-center">
                    <b-button variant="danger" @click="deleteContainerCode">Delete This Container Code</b-button>
                </b-col>
            </b-form-row>
        </form>
    </b-modal>
</template>

<script>
import EventBus from '@/EventBus'
import api from "@/backend/services/ContainerCodeService";

export default {
    name: "ModalAddOrEditContainerCode",
    data: () => ({
        container_code: {},
        original_code: '',
    }),
    computed: {
        title() {
            return this.original_code ? 'Update' : 'Create Container';
        },
    },
    methods: {
        reset() {
            this.original_code = '';
            this.container_code = {};
        },
        handleOk(e) {
            e.preventDefault()
            this.handleSubmit()
        },
        deleteContainerCode() {
            if (!confirm("Are you sure you want to delete this location code?")) {
                return
            }

            api.delete(this.$store.getters.axios, this.container_code.code)
                .then(() => {
                    this.$nextTick(() => {
                        this.$refs.modal.hide()
                        this.$store.dispatch('loadContainerCodes')
                    })
                })
        },
        handleSubmit() {
            if (this.original_code) {
                api.update(this.$store.getters.axios, this.original_code, this.container_code)
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$store.dispatch('loadContainerCodes')
                        })
                    })
            } else {
                api.create(this.$store.getters.axios, this.container_code)
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$store.dispatch('loadContainerCodes')
                        })
                    })
            }
        },
    },
    created() {
        EventBus.$on('editContainerCode', (payload) => {
            if (payload) {
                this.container_code = payload
                this.original_code = payload.code
            }
            this.$bvModal.show('modal-edit-inventory-location-code');
        })
    },
}
</script>

<style scoped>

</style>
