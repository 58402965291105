<template>
    <div class="container">
        <div class="h-100 row align-items-center">
            <div class="d-flex justify-content-center h-25">
            </div>
            <div class="d-flex justify-content-center h-50">
                <div class="user_card">
                    <img src="/images/logo.png" class="brand_logo" alt="Logo">
                    <div class="alert alert-danger" v-if="error">{{ error }}</div>
                    <div class="d-flex justify-content-center form_container">
                        <form>
                            <div class="input-group mb-3">
                                <div class="input-group-append">
                                    <span class="input-group-text"><font-awesome-icon :icon="['fas', 'user']" /></span>
                                </div>
                                <input id="email" type="text" v-model="email" class="form-control input_user"
                                       placeholder="username" required autofocus>
                            </div>
                            <div class="input-group mb-2">
                                <div class="input-group-append">
                                    <span class="input-group-text"><font-awesome-icon :icon="['fas', 'key']" /></span>
                                </div>
                                <input type="password" v-model="password" required class="form-control input_pass"
                                       placeholder="password">
                            </div>
                            <div class="d-flex justify-content-center mt-3 login_container">
                                <button type="submit" name="button" class="btn btn-dark" @click="login">Login
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="mt-4">
                        <div class="d-flex justify-content-center links">
                            <a href="#">Forgot your password?</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                email: "",
                password: "",
                error: false
            }
        },
        methods: {
            login(e) {
                e.preventDefault()
                if (this.password.length > 0) {
                    this.$store.dispatch('login', {
                        "username": this.email,
                        "password": this.password,
                        "router": this.$router,
                        "route": this.$route
                    });
                }
            },
        }
    }
</script>

<style>
    html, body {
        height: 100%;
    }
</style>

<style scoped lang="scss">
    @import "@/assets/scss/base.scss";

    .container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .user_card {
        margin-top: auto;
        margin-bottom: auto;
        background: whitesmoke;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px 30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 5px;

    }

    .form_container {
        margin-top: 20px;
    }

    .btn-dark {
        background: $primary-color-1;
    }

    .login_btn {
        width: 100%;
        background: whitesmoke !important;
        color: white !important;
    }

    .login_btn:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }

    .login_container {
        padding: 0 2rem;
    }

    .input-group-text {
        background: $primary-color-1 !important;
        color: white !important;
        border: 0 !important;
        border-radius: 0.25rem 0 0 0.25rem !important;
    }

    .input_user,
    .input_pass:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }

    a {
        color: #00544d;
    }

    a:hover {
        color: #00544d;
        text-decoration: none;
        font-weight: bold;
    }
</style>