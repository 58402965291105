<template>
    <b-modal
        id="modal-edit-inventory-item"
        ref="modal"
        :okTitle="title"
        :title="title"
        size="md"
        @hidden="reset"
        @ok="handleOk"
    >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-row>
                <b-col class="col-12">Container/Sub Container:</b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-select
                        v-model="item.container"
                        :options="container_options"
                        @change="containerSelected"
                        @input="containerChanging"
                    />
                </b-col>
                <b-col class="col-6">
                    <b-select
                        v-model="item.sub_container"
                        :disabled="sub_container_options.length == 0"
                        :options="sub_container_options"
                    />
                </b-col>
            </b-form-row>

            <b-form-row class="mt-4">
                <b-col class="col-12">Description:</b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-12">
                    <b-form-textarea v-model="item.desc" rows="4"/>
                </b-col>
            </b-form-row>

            <b-form-row v-if="item.id" class="mt-5">
                <b-col class="col-12 text-center">
                    <b-button variant="danger" @click="deleteItem">Delete This Item</b-button>
                </b-col>
            </b-form-row>
        </form>
    </b-modal>
</template>

<script>
import EventBus from '@/EventBus'
import apiItems from "@/backend/services/ItemsService";

export default {
    name: "ModalAddOrEditItem",
    data: () => ({
        item: {},
        sub_container_options: []
    }),
    computed: {
        title() {
            return this.item.id ? 'Update' : 'Create Item';
        },
        containers() {
            return this.$store.getters.containers;
        },
        container_options() {
            let options = [{
                value: '',
                text: ''
            }];

            if (this.containers) {
                for (let i = 0; i < this.containers.length; i++) {
                    options.push({
                        value: this.containers[i].id,
                        text: this.containers[i].container_code + "-" + this.containers[i].label,
                    })

                }
            }
            return options;
        },
    },
    methods: {
        containerChanging() {
            this.item.sub_container = ''
        },
        containerSelected(containerId) {
            let options = [{
                value: '',
                text: ''
            }];

            if (!containerId) {
                this.item.sub_container = ''
            }

            for (let i = 0; i < this.containers.length; i++) {
                let c = this.containers[i]
                if (c.id == containerId && c.sub_containers) {
                    for (let j = 0; j < c.sub_containers.length; j++) {
                        let sc = c.sub_containers[j]
                        options.push({
                            value: sc.id,
                            text: sc.label,
                        })
                    }
                }
            }

            this.sub_container_options = options;
        },
        reset() {
            this.item = {};
        },
        handleOk(e) {
            e.preventDefault()
            this.handleSubmit()
        },
        deleteItem() {
            if (!confirm("Are you sure you want to delete this item?")) {
                return
            }

            apiItems.delete(this.$store.getters.axios, this.item.id)
                .then(() => {
                    this.$nextTick(() => {
                        this.$refs.modal.hide()
                        this.$emit('changed')
                    })
                })
        },
        handleSubmit() {
            let item = {
                location: this.$store.getters.selectedLocation.id,
                container: this.item.container,
                sub_container: this.item.sub_container,
                view_mode: this.$store.getters.homeViewSettings.view_mode,
                desc: this.item.desc,
                q: this.$store.getters.homeViewSettings.selected_letter,
            }

            if (this.item.id) {
                apiItems.update(this.$store.getters.axios, this.item.id, item)
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$emit('changed')
                        })
                    })
            } else {
                apiItems.create(this.$store.getters.axios, item)
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$emit('changed')
                        })
                    })
            }
        },
    },
    created() {
        EventBus.$on('addItem', () => {
            this.$bvModal.show('modal-edit-inventory-item');
        })
        EventBus.$on('editItem', (payload) => {
            if (payload) {
                this.item = payload
                this.containerSelected(this.item.container)
            }
            this.$bvModal.show('modal-edit-inventory-item');
        })
    },
}
</script>

<style scoped>

</style>
