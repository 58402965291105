<template>
    <b-modal
        id="modal-edit-inventory-container"
        ref="modal"
        :okTitle="title"
        :title="title"
        size="md"
        @hidden="reset"
        @ok="handleOk"
    >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-row>
                <b-col class="col-6">Container Code:</b-col>
                <b-col class="col-6">Label:</b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-select v-model="container.container_code" :options="container_codes"/>
                </b-col>
                <b-col class="col-6">
                    <b-input v-model="container.label"/>
                </b-col>
            </b-form-row>

            <div v-if="container.sub_containers && container.sub_containers.length > 0">
                <b-form-row class="mt-3">
                    <b-col>
                        <h5>Sub Containers</h5>
                    </b-col>
                </b-form-row>

                <b-form-row v-for="sc in container.sub_containers" :key="sc.id" class="mt-1">
                    <b-col>
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-button variant="outline-danger" @click="deleteSubContainer(sc.id)">
                                    <font-awesome-icon :icon="['fas', 'trash']"/>
                                </b-button>
                            </b-input-group-prepend>
                            <b-input v-model="sc.label"/>
                        </b-input-group>
                    </b-col>
                </b-form-row>
            </div>

            <b-form-row v-if="container.id" class="mt-5">
                <b-col class="col-12 text-center">
                    <div class="btn-group">
                        <b-button type="button" class="btn btn-dark" @click="addSubContainer()">
                            New Subcontainer
                        </b-button>
                        <b-button class="btn btn-danger" type="button" @click="deleteContainer">
                            Delete Container
                        </b-button>
                    </div>
                </b-col>
            </b-form-row>
        </form>
    </b-modal>
</template>

<script>
import EventBus from '@/EventBus'
import api from "@/backend/services/ContainerService";

export default {
    name: "ModalAddOrEditContainer",
    data: () => ({
        container: {
            sub_containers: []
        },
    }),
    computed: {
        title() {
            return this.container.id ? 'Update' : 'Create Container';
        },
        container_codes() {
            let options = []
            let codes = this.$store.getters.container_codes
            for (let i = 0; i < codes.length; i++) {
                options.push({
                    value: codes[i].code,
                    text: codes[i].code,
                })
            }
            return options;
        },
    },
    methods: {
        reset() {
            this.container = {};
        },
        handleOk(e) {
            e.preventDefault()
            this.handleSubmit()
        },
        deleteContainer() {
            if (!confirm("Are you sure you want to delete this container?")) {
                return
            }
            api.delete(this.$store.getters.axios, this.container.id)
                .then(() => {
                    this.$nextTick(() => {
                        this.$refs.modal.hide()
                        this.$store.dispatch('loadContainers')
                    })
                })
        },
        addSubContainer(){
            if(!this.container.sub_containers){
                this.container.sub_containers = []
            }

            this.container.sub_containers.push({
                label: ""
            })
        },
        deleteSubContainer(id){
            if(!confirm("Are you sure you want to delete this sub container?")){
                return
            }

            for(let i = 0; i < this.container.sub_containers.length; i++){
                let sc = this.container.sub_containers[i]
                if(sc.id == id){
                    this.container.sub_containers.splice(i, 1);
                }
            }
        },
        handleSubmit() {
            let loc = this.$store.getters.selectedLocation.id
            if (this.container.id) {
                api.update(this.$store.getters.axios, this.container.id, loc, this.container)
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$store.dispatch('loadContainers')
                        })
                    })
            } else {
                api.create(this.$store.getters.axios, {
                    location: loc,
                    container_code: this.container.container_code,
                    label: this.container.label
                })
                    .then(() => {
                        this.$nextTick(() => {
                            this.$refs.modal.hide()
                            this.$store.dispatch('loadContainers')
                        })
                    })
            }
        },
    },
    created() {
        EventBus.$on('editContainer', (payload) => {
            if (payload) {
                this.container = payload
            }
            this.$bvModal.show('modal-edit-inventory-location');
        })
    },
}
</script>

<style scoped>

</style>
