<template>
    <div v-if="isAuthenticated">
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :color="loaderColor"
            :is-full-page="true"

            :loader="loaderType"
        />
        <b-sidebar
            id="sidebar-footer"
            backdrop
            no-header
            shadow
            width="280px"
        >
            <template #footer="">
                <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                    <strong class="mr-auto"></strong>
                    <b-button @click="logout">Logout</b-button>
                </div>
            </template>
            <div class="px-3 py-2">
                <router-link class="nav-link" to="home">Home</router-link>
                <router-link class="nav-link" to="locations">Locations</router-link>
                <router-link class="nav-link" to="containers">Containers</router-link>
                <router-link class="nav-link" to="container_codes">Container Codes</router-link>
            </div>
        </b-sidebar>
        <div class="container-fluid px-0">
            <navbar/>
            <router-view :key="$route.fullPath" class="px-0"/>
        </div>
    </div>
    <Login v-else/>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Login from '@/components/Login.vue';
import Navbar from '@/components/Navbar'
import EventBus from '@/EventBus'

export default {
    name: 'App',
    components: {
        Loading,
        Login,
        Navbar,
    },
    data: () => ({
        loaderType: "bars",
        loaderColor: "rgba(44, 62, 80, 0.9)",
    }),
    computed: {
        isLoading: function () {
            return this.$store.getters.loading;
        },
        isAuthenticated: function () {
            let settings = this.$store.getters.userSettings;
            return settings.token != '';
        },
        errors: function () {
            return this.$store.getters.ajaxErrors;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
            if(this.$route.name != "Home"){
                this.$router.replace("/")
            }
        }
    },
    created() {
        let store = this.$store;

        if (this.isAuthenticated) {
            this.$store.dispatch('loadContainerCodes')
            this.$store.dispatch('loadContainers')
        }

        EventBus.$on('ajaxErrors', function () {
            let errors = store.getters.ajaxErrors;
            if (errors != undefined) {
                for (let i = 0; i < errors.length; i++) {
                    let message = errors[i];
                    this.$toasted.show(message.html, {
                        duration: 5000,
                        className: 'ajax-error',
                        icon: 'exclamation-triangle'
                    });
                }

                store.dispatch('clearAjaxErrors');
            }
        })
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/base.scss";

a {
    color: #00544d;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.ajax-error {
    background: $error-color-1 !important;
}

.b-sidebar-outer {
    position: absolute;
    top: 60px;
    z-index: 10000;
}
</style>
